import React, { useState, useEffect } from 'react';
import Topsection from '../components/sharing/topsection';
import Breadcrumb from '../components/sharing/breadcrumbs';
import axios from "axios";
export const getServerSideProps = (async ({ locale, query, params }) => {

    console.log('locale', locale);

    return {
        props: {
            locale
        }
    }
}
)

function AdvancedSearch() {
    const [about, setAbout] = useState('');
    useEffect(() => {
        axios.post(process.env.REACT_APP_BACKEND_URL + '/infowebsite/list',
        ).then(response2 => {
            setAbout(response2.data.result.fullAbout)
        })
    }, []);


    const breadCurmbData = [
        {
            title: "من نحن",
            link: '',
        },
    ];

    return (
        <div>
            <Topsection data={{ text: 'من نحن', img: require('../assets/images/11.png') }} />
            <Breadcrumb data={breadCurmbData} />
            <div className="aboutus">
                <div className="details" dangerouslySetInnerHTML={{ __html: about }}></div>

            </div>
        </div>
    );
}

export default AdvancedSearch;
